html {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: "Space Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #263238;
  background-image: linear-gradient(315deg, #263238 0%, #2d3c43 74%);
}

#root {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

* {
  box-sizing: border-box;
}
